import React from "react";
import { AtPaySelectMethodOption } from "./select-method-option";
import { LoadingIcon } from "../loading-icon";
import * as Configs from "../../config";

export const AtPaySelectPayment = (props: any) => {
  const {
    loading,
    activeIdx,
    isAllowedContinue,
    setMethod,
    setActiveIdx,
    startPayment,
    options,
  } = props;

  const handleOnClick = (value: string, index: number) => {
    // Only Octopus and PayMe is supported atm
    if (Configs.acceptPayment.indexOf(value) < 0) return false;

    if (activeIdx === index) {
      // Reset
      setMethod("");
      setActiveIdx(-1);
      return;
    }
    setMethod(value);
    setActiveIdx(index);
    return;
  };

  return (
    <React.Fragment>
      <div className="mt-[25px] pb-[50px]">
        <h3 className="font-[600] text-[20px] text-left pb-[10px]">
          Payment Methods
        </h3>

        <div className="w-full">
          {options.map((item: any, i: any) => {
            return (
              <div key={i}>
                <AtPaySelectMethodOption
                  active={activeIdx === i}
                  onClick={() => handleOnClick(item, i)}
                  name={item}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Continue Button */}
      <div className="fixed left-0 bottom-[-25px] pb-[50px] w-full text-center">
        <button
          className={`${
            isAllowedContinue() ? $btn : $disabledBtn
          } w-[180px] py-[15px] rounded-[20px] text-[18px] border-[1px] border-slate-200 shadow-md`}
          onClick={() => startPayment()}
          disabled={!isAllowedContinue()}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <LoadingIcon size={26} color="#427cdd" className="text-[10px]" />
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

const $btn =
  "bg-gradient-to-r from-[#f894fa99] from-10% via-[#427cdd] via-40% to-[#6CBADB] to-90% ";
const $disabledBtn = "bg-[#dddddd]";
