import React, { useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";

export const AtPayConfirmPaymentQR = (props: any) => {
  const { webLink = "" } = props;
  const [init, setInit] = useState(false);

  useEffect(() => {
    // Assign QR Code
    const qrCode: any = new QRCodeStyling({
      width: 150,
      height: 150,
      type: "svg",
      data: webLink,
      image: "/assets/payme-logo-p-red-circle.png",
      dotsOptions: {
        color: "#dc0010",
        type: "dots",
      },
      backgroundOptions: {
        color: "#fff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
        imageSize: 0.5,
        hideBackgroundDots: false,
      },
      cornersSquareOptions: {
        type: "extra-rounded",
      },
      cornersDotOptions: {
        type: "dot",
      },
    });

    const qr = document.getElementById("qr-canvas");
    if (!qr) setInit(false);
    if (qr) {
      !init && qrCode.append(qr);
      setInit(true);
    }
  }, [webLink, init, setInit]);

  if (!webLink) return null;

  return (
    <div>
      <div id="qr-canvas" />
    </div>
  );
};
