import React from 'react'

export const VisaMasterCardView = (props: any) => {
  const {cardNumber,cardHolder, expireDate, cvv, handleCreditCard, handleCardHolder, handleExpireDate, handleCVV}  = props;

  return (
    <div className="shadow-md flex flex-col md:p-[5px] p-[10px] rounded-[15px] bg-white to-100% md:min-h-[300px]">
    {/* Card Info Body */}
    {/* image */}
    <img
      alt="visa master"
      className="w-[150px] h-auto"
      src="/assets/visamaster.png"
    />

    {/* card number */}
    <div className={`${$inputContainer} mt-[15px] w-[100%]`}>
      <p className={$inputTitle}>Card Number</p>
      <input
        placeholder="0000  0000  0000  0000"
        className={$input}
        type="text"
        maxLength={19}
        value={cardNumber}
        onChange={handleCreditCard}
      />
    </div>

    <div className="flex flex-row mt-[15px]">
      {/* card holder */}
      <div className={`${$inputContainer} w-[75%]`}>
        <p className={$inputTitle}>Card Holder</p>
        <input
          placeholder="Card Holder"
          className={$input}
          type="text"
          value={cardHolder}
          onChange={handleCardHolder}
        />
      </div>

      {/* expire date */}
      <div className="flex flex-row">
        <div className={`${$inputContainer} w-[100%]`}>
          <p className={$inputTitle}>Expire Date</p>
          <input
            placeholder="MM/YYYY"
            className={$input}
            type="text"
            value={expireDate}
            maxLength={7}
            onChange={handleExpireDate}
          />
        </div>

        {/* cvv */}
        <div className={`${$inputContainer} w-[75%]`}>
          <p className={$inputTitle}>CVV</p>
          <input
            placeholder="123"
            className={$input}
            type="text"
            maxLength={3}
            value={cvv}
            onChange={handleCVV}
          />
        </div>
      </div>
    </div>
  </div>
  )
}

const $inputContainer =
  "flex flex-col border-[1px] rounded-[10px] h-[75px] border-white px-[20px]";
const $inputTitle = "text-[#9A9A9A] text-[12px]";
const $input =
  "w-[100%] mt-[5px] bg-transparent text-[#9A9A9A] !placeholder-[#9A9A9A] h-[30px] text-[16px] tracking-wide focus:ring-0 !outline-none border-b-2 border-b-[#9A9A9A]";
