import React, { useState } from "react";
import {
  Container,
  openSuccessNotification,
  openWarningNotification,
} from "../components";
import { OrderForm } from "src/components/order-form";
import { usePaymentStore } from "src/stores";
import { LoadingIcon } from "src/components/loading-icon";
import { CheckForm } from "src/components/check-form";
import { LoadCardOrder } from "src/components/load-card-order";
import { LoadUpriseOrder } from "src/components/load-uprise-order";

const merchant = "middle-east";
const currency = "HKD";
const rate = 1;
const method: any = "uprise";

export const MiddleEast = (props: any) => {
  const [form, setForm] = useState("place-form"); // place-form, check-form
  const [amount, setAmount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [orderDetail, setOrderDetail] = useState<any>(null);

  const { createPaymentOrder, checkPaymentOrder } = usePaymentStore();

  const checkOrder = async () => {
    if (!orderId) return openWarningNotification("Order id has to be filled");

    setLoading(true);
    let order = await checkPaymentOrder(
      {
        orderId,
      },
      merchant
    );
    setLoading(false);
    if (typeof order !== "object")
      return openWarningNotification("Order ID is invalid");
    openSuccessNotification("Order is retrieved");
    setOrderDetail(order);
  };

  const createOrder = async () => {
    if (!amount || amount <= 0)
      return openWarningNotification("Order amount has to be larger than 0");

    setLoading(true);
    const currencyAmount = Number((Number(amount) / rate).toFixed(2));
    let order = await createPaymentOrder(
      {
        amount: currencyAmount,
        currency: currency,
        method: method,
      },
      merchant
    );
    setLoading(false);
    if (typeof order !== "object")
      return openWarningNotification("Order cannot be created");
    openSuccessNotification("Order is created");
    setOrder(order);
  };

  const getStatusTxt = (status: string) => {
    switch (status) {
      case "pending":
        return "Order cannot be created, please create another order";
      case "order_created":
        return "Order created, waiting for payment...";
      case "done":
        return "PAID";
      default:
        return "Rejected";
    }
  };

  return (
    <Container from="os" theme="light">
      <div className="flex flex-col">
        <h1 className="text-[#026C7C] text-[30px] font-[700] os-font leading-[36px]">
          M.E. Payment Portal
        </h1>

        {/* Select Buttons */}
        <div className="flex flex-row space-x-[15px] mt-[20px]">
          <button
            className={`${$btnStyle} ${
              form === "place-form" ? `${$activeBtn}` : ""
            }`}
            onClick={() => {
              setForm("place-form");
              setOrder(null);
              setOrderDetail(null);
            }}
          >
            Place Order
          </button>
          <button
            className={`${$btnStyle} ${
              form === "check-form" ? `${$activeBtn}` : ""
            }`}
            onClick={() => {
              setForm("check-form");
              setOrder(null);
              setOrderDetail(null);
            }}
          >
            Check Order
          </button>
        </div>

        <div className="pt-[15px]">
          {form === "place-form" && (
            <OrderForm
              value={amount}
              setValue={setAmount}
              submit={createOrder}
              bgColor={"#026C7C"}
            />
          )}
          {form === "check-form" && (
            <CheckForm
              value={orderId}
              setValue={setOrderId}
              submit={checkOrder}
              bgColor={"#026C7C"}
            />
          )}
        </div>

        {order && method === "airwallex" && (
          <div className="pt-[20px]">
            <LoadCardOrder
              id={order.order_id}
              client_secret={order.extra.client_secret}
              amount={order["amount"]}
              currency={currency}
              setOrder={setOrder}
              loading={loading}
              setLoading={setLoading}
              txtColor={"#026C7C"}
            />
          </div>
        )}

        {order && method === "uprise" && (
          <div className="pt-[20px]">
            <LoadUpriseOrder
              id={order.order_id}
              link={order.extra?._links?.redirect?.href}
              amount={order["amount"]}
              currency={currency}
              setOrder={setOrder}
              loading={loading}
              setLoading={setLoading}
              txtColor={"#026C7C"}
            />
          </div>
        )}

        {orderDetail && (
          <div className="pt-[20px]">
            <p className={$orderTxt}>
              <span className={$labelStyle}>Order ID</span>
              {orderDetail.id}
            </p>
            <p className={$orderTxt}>
              <span className={$labelStyle}>Amount</span>
              {orderDetail.amount} {orderDetail.currency}
            </p>
            <p className={$orderTxt}>
              <span className={$labelStyle}>Status</span>
              {getStatusTxt(orderDetail.status)}
            </p>
            <p className={$orderTxt}>
              <span className={$labelStyle}>Created At</span>
              {orderDetail.created_at}
            </p>
            <p className={$orderTxt}>
              <span className={$labelStyle}>Last Updated</span>
              {orderDetail.modified_at}
            </p>
          </div>
        )}
      </div>

      {loading && (
        <div className="w-full h-full absolute top-0 left-0 items-center justify-center flex">
          <LoadingIcon color="#6018C3" />
        </div>
      )}
    </Container>
  );
};

const $btnStyle =
  "w-full bg-[#fff] py-[10px] rounded-md text-[#000] border-[1px] border-[#026C7C]";
const $activeBtn = "!bg-[#026C7C] !text-[#fff]";
const $labelStyle = "w-[100px] inline-block font-[500] text-[#000]";
const $orderTxt = "pb-[5px] text-[#000]";
