import React, { useCallback, useEffect } from "react";
import { Container } from "../components";
import { usePaymentStore } from "src/stores";
import { useLocation } from "react-router";

const merchant = "n3-ceramics";

export const N3CeramicsConfirmReceipt = (props: any) => {
  const { confirmPaymentReceipt } = usePaymentStore();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("orderId") || "";
  const method = searchParams.get("method") || "stripe";

  const confirmReceiptCb = useCallback(async () => {
    await confirmPaymentReceipt(
      {
        orderId: orderId,
      },
      merchant
    );
  }, [orderId, confirmPaymentReceipt]);

  useEffect(() => {
    if (!orderId || !method) return;
    confirmReceiptCb();
  }, [orderId, method, confirmReceiptCb]);

  if (!orderId) return null;

  return (
    <Container from="onets" theme="light">
      <div className="flex flex-col items-center justify-center w-full p-[10px]">
        <h1 className="text-[16px] font-[500] leading-[36px] n3-txt uppercase">
          N3 Ceramics
        </h1>

        <img
          src="/assets/paid.gif"
          alt="paid"
          className="w-[200px] object-fit"
        />

        <p className="mt-[20px] n3-txt text-center font-[400] text-slate-500 text-[12px]">
          The receipt {orderId} is confirmed
        </p>
      </div>
    </Container>
  );
};
