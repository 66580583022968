import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Configs from "src/config";
import { LoadStripeComponent } from "./load-stripe-component";

const stripePromise = loadStripe(Configs.STRIPE_KEY);

export const LoadStripeOrder = (props: any) => {
  const {
    orderId,
    clientSecret,
    amount,
    currency,
    setLoading,
    merchant,
    loading,
    idx,
    email,
    name,
    country,
  } = props;

  const appearance = {
    theme: "night",
  };

  const options: any = {
    clientSecret,
    appearance,
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {clientSecret && (
        <>
          <Elements options={options} stripe={stripePromise}>
            <LoadStripeComponent
              orderId={orderId}
              amount={amount}
              currency={currency}
              setLoading={setLoading}
              merchant={merchant}
              loading={loading}
              clientSecret={clientSecret}
              idx={idx}
              email={email}
              name={name}
              country={country}
            />
          </Elements>
        </>
      )}
    </div>
  );
};
