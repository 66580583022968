import axios from "axios";
import * as Configs from "../config";
import { isResultSuccess, setHeader } from "./api";

export class AtPayStore {
  async createPaymentOrder(payload: any) {
    console.log(payload);
    const url = `${Configs.ASIATOP_API_URL}/at-pay/create-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }

  async checkPaymentOrder(param: any) {
    const url = `${Configs.ASIATOP_API_URL}/at-pay/check-order${
      param ? param : ""
    }`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }

  async submitCardPayment(payload: any) {
    const url = `${Configs.ASIATOP_API_URL}/at-pay/card-payment`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }
}

export const STORE_AT_PAY = "atPayStore";
