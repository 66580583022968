import React from "react";

export const LoadUpriseOrder = (props: any) => {
  const {
    id,
    amount,
    currency,
    setOrder,
    loading,
    setLoading,
    txtColor,
    link,
  } = props;

  if (!id || !currency || !link) return null;

  const pay = async () => {
    if (loading) return;
    setLoading(true);
    window.open(link, "_blank");
    setLoading(false); // Example: sets loading state
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <p>
        <span className={$labelStyle}>Order ID</span> {id}
      </p>
      <button id="submit" className="full mt-[20px]" onClick={() => pay()}>
        Pay {amount} {currency}
      </button>
      <button
        className="full mt-[20px] !bg-transparent"
        onClick={() => setOrder(null)}
        style={{ color: txtColor }}
      >
        Clear all
      </button>
    </div>
  );
};

const $labelStyle = "w-[100px] inline-block font-[500]";
