import React, { useCallback, useState } from "react";
import {
  Container,
  openSuccessNotification,
  openWarningNotification,
} from "../components";
import { usePaymentStore } from "src/stores";
import { LoadingIcon } from "src/components/loading-icon";
import { useLocation } from "react-router";
import { ClientForm } from "src/components/client-form";
import { LoadStripeOrder } from "src/components/load-stripe-order";

const merchant = "n3-ceramics";
const currency = "USD";
const rate = 1;
const idx = String(Math.floor(Math.random() * 9));
const maxAmountAllow = 10000;

export const N3Ceramics = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [email, setEmail] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [step, steStep] = useState<any>("form"); // form, payment

  const { createPaymentOrder, updateEmailPaymentOrder } = usePaymentStore();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const amountParams = searchParams.get("amount") || "0";
  const amount = Number(amountParams);

  const createOrderCb = useCallback(async () => {
    if (!amount || amount <= 0)
      return openWarningNotification("Order amount has to be larger than 0");

    setLoading(true);
    const currencyAmount = Number((Number(amount) / rate).toFixed(2));
    let order = await createPaymentOrder(
      {
        amount: currencyAmount,
        currency: currency,
        method: "stripe",
        fee: "0",
      },
      merchant
    );
    setLoading(false);
    if (typeof order !== "object")
      return openWarningNotification("Order cannot be created");
    openSuccessNotification("Order is created");
    setOrder(order);
    return order;
  }, [createPaymentOrder, amount]);

  if (!amount) return null;

  const getName = () => {
    switch (idx) {
      case "0":
        return "Ceramic Vase Turquois Pollen by Adam Knoche Glaze";
      case "1":
        return "Large-Scale, Glazed Ceramic Llama, Italy, circa 1970";
      case "2":
        return "Modern Ceramic Sculpture by Jerilyn Virden";
      case "3":
        return "Vase, Ceramics by Hans Hedberg, Biot, France";
      case "4":
        return "D.N.A.";
      case "5":
        return `Adam Knoche Ceramic Vessel “Basalt Scoria” Clay, Porcelain, Glaze,
        Cone Six Oxid`;
      case "6":
        return `Adam Knoche Contemporary Red Ceramic Vase "Cadmium Fissure" Clay, Glaze 2022`;
      case "7":
        return `Adam Knoche Contemporary Vase Gum Sap Porcelain, Pink Orange
        Contemporary Clay`;
      case "8":
      default:
        return `Ceramic Vase Bleached Amarillo Adam Knoche Yellow Contemporary
        Glazed Vessel`;
    }
  };

  const submitClientForm = async () => {
    if (Number(amount) > maxAmountAllow)
      return openWarningNotification(
        `Order amount cannot be greater than ${maxAmountAllow}`
      );
    if (!email) return openWarningNotification("Please enter email");
    const order = await createOrderCb();
    const isUpdated = await updateEmailPaymentOrder(
      { email: email.trim(), orderId: order["order_id"] },
      merchant
    );
    if (!isUpdated)
      return openWarningNotification("Cannot proceed at the moment");
    steStep("payment");
  };

  return (
    <Container from="n3-ceramics" theme="light">
      {loading && (
        <div className="z-[9999] w-full h-full fixed top-0 left-0 items-center justify-center flex">
          <LoadingIcon color="#ea437a" />
        </div>
      )}

      <div className="flex flex-col w-full p-[10px] overflow-hidden overflow-x-auto">
        <h1 className="text-[16px] font-[500] leading-[36px] n3-txt uppercase">
          N3 Ceramics
        </h1>

        {/* Voucher Name */}
        <div className="pt-[15px] flex flex-row items-start">
          <img
            src={`/assets/ceramics/${idx}.webp`}
            alt="voucher"
            className="h-[250px] object-fit"
          />
          <div className="ml-[40px] py-[15px]">
            <h5 className={$voucherTitleStyle}>{getName()}</h5>
            <p className={$voucherAmountStyle}>USD ${amountParams}</p>
          </div>
        </div>

        <div className="pt-[20px]">
          {step === "form" && (
            <ClientForm
              value={email}
              setValue={setEmail}
              submit={submitClientForm}
              name={name}
              setName={setName}
              country={country}
              setCountry={setCountry}
            />
          )}

          {order && step === "payment" && (
            <LoadStripeOrder
              clientSecret={order["clientSecret"]}
              amount={amount}
              currency={"USD"}
              setLoading={setLoading}
              orderId={order["order_id"]}
              merchant={merchant}
              loading={loading}
              idx={idx}
              email={email}
              name={name}
              country={country}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

const $voucherTitleStyle =
  "text-[10px] font-[500] text-slate-400 n3-txt leading-[14px]";
const $voucherAmountStyle =
  "text-[14px] font-[600] text-slate-600 n3-txt mt-[10px]";
