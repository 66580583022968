import React from "react";
import { Container } from "../components";

export const PaymentError = (props: any) => {
  // Cancel Order
  const { location } = props;
  const errorMessage =
    (location.state && location.state.message) ||
    "There is an error processing the payment, please try again";

  return (
    <Container>
      <div className="flex flex-col items-center justify-center">
        <img
          src="/assets/warning.gif"
          alt="warning"
          className="w-[200px] object-fit"
        />
        <p className="mt-[20px]">{errorMessage}</p>
        <button
          onClick={() => {
            window.open("", "_self", "");
            window.close();
          }}
          className="mt-[30px]"
        >
          Close and Return
        </button>
      </div>
    </Container>
  );
};
