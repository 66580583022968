import React from "react";
import { LoadingIcon } from "../loading-icon/";
import { openWarningNotification } from "../notification/";
import { AtPayConfirmPaymentQR } from "./confirm-payment-qr";

export const PayMePaymentView = (props: any) => {
  const { isCheckingPayment, paymentDetails = {}, checkPaymentOrder } = props;
  const openPayMe = (url: string) => {
    if (!url) return openWarningNotification("Failed to open payment link");
    return window.open(url);
  };

  return (
    <div>
      <h3 className="font-[600] text-[20px] text-left pb-[10px]">
        Instruction
      </h3>
      <div className="flex justify-center items-center bg-white min-h-[350px] p-[20px] rounded-[20px] cursor-pointer">
        <div className="w-full flex flex-col items-center justify-center p-[20px]">
          {!paymentDetails.extra.webLink && (
            <img
              src={`/assets/payme.png`}
              alt="payme-icon"
              className="w-[80px] object-fit"
            />
          )}
          {paymentDetails.extra.webLink && (
            <AtPayConfirmPaymentQR webLink={paymentDetails.extra.webLink} />
          )}

          <div className="w-full pt-[30px]">
            <p className="text-[12px] text-[#334155] text-left">
              Method 1: Scan Payment QR Code from PayMe App
            </p>
            <p className="text-[12px] text-[#334155] text-left pt-[5px]">
              Method 2: Click “Pay Now” to access PayMe app for Payment
            </p>
          </div>
        </div>
      </div>

      {/* Pay Button  */}
      <div className="fixed left-0 bottom-[25px] w-full text-center">
        <p
          className={`${
            isCheckingPayment
              ? "text-[#ddd] cursor-not-allowed"
              : "text-[#0077CC] cursor-pointer"
          } text-[18px]`}
          onClick={() => checkPaymentOrder()}
        >
          Already Paid
        </p>

        <div className="mt-[15px]">
          <button
            className={`${
              !isCheckingPayment ? $btn : $disabledBtn
            } w-[180px] py-[15px] rounded-[20px] text-[18px]`}
            onClick={() => openPayMe(paymentDetails.extra.webLink)}
            disabled={isCheckingPayment}
          >
            {isCheckingPayment ? (
              <div className="flex justify-center items-center">
                <LoadingIcon
                  size={26}
                  color="#427cdd"
                  className="text-[10px]"
                />
              </div>
            ) : (
              "Pay Now"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const $btn =
  "bg-gradient-to-r from-[#f894fa99] from-5% via-[#427cdd] via-40% to-[#6CBADB] to-90%";
const $disabledBtn = "bg-[#dddddd]";
