import React from "react";
// import * as Utils from "../../utils";

export const AtPayOrderSummary = (props: any) => {
  const { currency, amount, orderId } = props;

  // TODO: update conversion by currency (For Uprise)
  // const getCurrencyValueByMethod = (
  //   paymentMethod: string,
  //   defaultCurrency: string
  // ) => {
  //   //Conversation 1:0.12
  //   if (paymentMethod === "yunshanfu") {
  //     return (
  //       <div className="flex flex-row justify-center items-center">
  //         <p className="text-[20px] font-[600] text-black">
  //           EUR €{Utils.getEurAmount(Number(amount))}
  //         </p>
  //         <p className="ml-[10px] text-[#8D8F99]">
  //           ({defaultCurrency} ${Number(amount).toFixed(2)})
  //         </p>
  //       </div>
  //     );
  //   }

  //   return (
  //     <p className={$txtStyle}>
  //       {currency} ${Number(amount).toFixed(2)}
  //     </p>
  //   );
  // };

  return (
    <div className="p-[20px] flex flew-row justify-between items-center bg-white w-full rounded-[20px] shadow-md">
      <div className="flex flex-col">
        <p className={$titleStyle}>Total Amount</p>
        <div>
          <p className={$txtStyle}>
            {currency} {Number(amount).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <p className={`${$titleStyle} text-right`}>Order ID </p>
        <p className={$txtStyle}>{orderId}</p>
      </div>
    </div>
  );
};

const $txtStyle = "text-[20px] font-[600] text-black pt-[5px]";
const $titleStyle = "text-[16px] font-[600] text-[#8D8F99]";
