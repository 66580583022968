import React, { useEffect } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { openSuccessNotification, openWarningNotification } from ".";

export const LoadStripeComponent = (props: any) => {
  const {
    orderId,
    amount,
    currency,
    setLoading,
    merchant,
    loading,
    clientSecret,
    idx,
    email,
    name,
    country,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case "succeeded":
            openSuccessNotification("Payment succeeded!");
            break;
          case "processing":
            openWarningNotification("Your payment is processing.");
            break;
          case "requires_payment_method":
            openWarningNotification(
              "Your payment was not successful, please try again."
            );
            break;
          default:
            openWarningNotification("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    // Confirm payment
    const payment: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: email,
            ...(name && { name: name }),
            ...(country && { address: { country: country } }),
          },
        },
        return_url: `${window.location.origin}/${merchant}-success?orderId=${orderId}&method=stripe&idx=${idx}`,
      },
    });
    const { error } = payment;
    const { type } = error;
    if (type === "card_error" || type === "validation_error") {
      openWarningNotification(error.message);
    } else {
      openWarningNotification("Cannot proceed payment at the moment");
    }

    setLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        id="submit"
        className="full mt-[40px]"
        disabled={loading || !stripe || !elements || !clientSecret}
        // onClick={(e) => pay(e)}
      >
        Pay {amount} {currency}
      </button>
    </form>
  );
};
