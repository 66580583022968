import React, { useEffect } from "react";
import { history } from "src/stores";

export const LoadOctopusOrder = (props: any) => {
  const { orderId, amount, returnUrl } = props;

  useEffect(() => {
    if (!orderId || !amount) return history.push("/error");
  }, [orderId, amount]);

  if (!orderId || !amount) return null;

  return (
    <div className="flex flex-col w-[100%] h-[100%]">
      {/* Body */}
      <div className="flex flex-col h-full items-center pt-[20px]">
        <p className="text-[11px] text-slate-300 text-center w-full">
          Order ID: {orderId}
        </p>

        <div className="w-full flex justify-center py-[50px]">
          <img
            src="/assets/secure-payment.png"
            alt="octopus-card"
            className="w-[170px] object-fit"
          />
        </div>

        <p className="text-[11px] text-[#334155] text-center w-full">
          Click Pay Now will redirect you to the payment guide
        </p>

        <div className="fixed bottom-[10%] text-center rounded-full bg-gradient-to-r w-[200px] px-[50px] py-[11px] from-[#fdad1d] to-[#f65a17]">
          {/* Pay Now Button */}
          <form
            name="payForm"
            method="post"
            action={process.env.REACT_APP_MERCHANT_URL}
          >
            <input
              type="hidden"
              name="merchantId"
              value={process.env.REACT_APP_MERCHANT_ID}
            />

            {/* The Max amount is 5000 HKD */}
            <input type="hidden" name="amount" value={amount} />
            <input type="hidden" name="orderRef" value={orderId} />
            <input type="hidden" name="currCode" value="344" />
            <input type="hidden" name="payMethod" value="OCTOPUS" />

            <input
              type="hidden"
              name="successUrl"
              value={`${process.env.REACT_APP_RETURN_URL}/octopus-checkout-success`}
            />
            <input
              type="hidden"
              name="failUrl"
              value={`${process.env.REACT_APP_RETURN_URL}${returnUrl}`}
            />
            <input
              type="hidden"
              name="errorUrl"
              value={`${process.env.REACT_APP_RETURN_URL}${returnUrl}`}
            />
            <input type="hidden" name="lang" value="E" />
            <input
              type="submit"
              className="text-[#fff] font-bold"
              value="Pay Now"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
