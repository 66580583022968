import React, { useEffect, useState } from "react";
import { Container } from "../components";
import {
  createElement,
  ElementType,
  loadAirwallex,
} from "airwallex-payment-elements";
import { history } from "src/stores";
import { useLocation } from "react-router";
import { AIRWALLEX_ENV } from "src/config";

export const Wechat = (props: any) => {
  const [elementShow, setElementShow] = useState(false); // Example: set loading state for element
  const [errorMessage, setErrorMessage] = useState(""); // Example: set error state
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const client_secret = searchParams.get("client_secret");
  const currency = searchParams.get("currency");
  useEffect(() => {
    console.log("Logging to see the information", {
      id,
      client_secret,
      currency,
    });
    if (!id || !client_secret || !currency)
      return history.push("/wechat-error");
    const loadWeChat = async () => {
      await loadAirwallex({
        env: AIRWALLEX_ENV, // Can choose other production environments, 'staging | 'demo' | 'prod'
        origin: window.location.origin, // Setup your event target to receive the browser events message
      });

      const intent = {
        id: id,
        currency: "HKD",
        client_secret: client_secret,
      };

      createElement("wechat" as ElementType, {
        intent,
        origin: window.location.origin,
      })?.mount("wechat"); // This 'wechat' id MUST MATCH the id on your empty container created in Step 3
    };

    loadWeChat();
    // STEP #6: Add an event listener to handle events when the element is mounted
    const onReady = (event: CustomEvent): void => {
      console.log("In the onReady event handler");
      /**
       * Handle event on element mount
       */
      setElementShow(true);
      console.log(`Element ready, ${JSON.stringify(event.detail)}`);
    };

    // STEP #7: Add an event listener to handle events when the payment is successful
    const onSuccess = (event: CustomEvent): void => {
      /**
       * ... Handle event on success
       */
      history.push("/wechat-checkout-success");
    };

    // STEP #8: Add an event listener to handle events when the payment procedure has failed
    const onError = (event: CustomEvent): void => {
      /**
       * ... Handle event on error
       */
      const { error } = event.detail;
      setErrorMessage(error.message ?? JSON.stringify(error)); // Example: set error message
      console.error("There is an error", error);
      history.push("/wechat-error");
    };
    const domElement = document.getElementById("wechat");
    domElement?.addEventListener("onReady", onReady as EventListener);
    domElement?.addEventListener("onSuccess", onSuccess as EventListener);
    domElement?.addEventListener("onError", onError as EventListener);

    return () => {
      domElement?.removeEventListener("onReady", onReady as EventListener);
      domElement?.removeEventListener("onSuccess", onSuccess as EventListener);
      domElement?.removeEventListener("onError", onError as EventListener);
    };
  }, [id, client_secret, currency]); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  // Example: Custom styling for the dropIn container, can be placed in css
  const containerStyle = {
    width: "540px",
    margin: "48px auto",
  };

  if (!id || !client_secret || !currency) return null;

  return (
    <Container>
      <div className="flex flex-col items-center justify-center">
        <p className="pb-[20px]">Wechat Pay</p>
        {errorMessage.length > 0 && (
          <p id="error" className="pb-[20px]">
            {errorMessage}
          </p>
        )}
        <div
          id="wechat"
          style={{ ...containerStyle, display: elementShow ? "block" : "none" }}
        ></div>
      </div>
    </Container>
  );
};
