import React from "react";
export const VideoBg = (props: any) => {
  const { type = "relative" } = props;
  return (
    <div
      className={`${type} h-[120px] w-full max-w-[100%] sm:max-w-[640px] mx-auto`}
    >
      {/* bg */}
      <div id="bg" className={`top-0 left-0 z-0 w-[100%] h-[100%]  ${type}`}>
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-full h-full object-cover"
        >
          <source src={`/assets/os-bg.mp4`} type="video/mp4" />
        </video>
      </div>

      {/* Opacity */}
      <div className="opacity z-10" />
    </div>
  );
};
