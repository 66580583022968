import React, { useEffect, useState } from "react";
import { Container, openWarningNotification } from "../components";
import { LoadingIcon } from "src/components/loading-icon";
import { history, useAtPayStore } from "src/stores";
import { useLocation } from "react-router";
import { AtPayConfirmPaymentBody } from "src/components/at-pay/confirm-payment-body";
import { AtPayOrderSummary } from "src/components/at-pay/order-summary";
import { AtPaySelectPayment } from "src/components/at-pay/select-payment";
import * as Configs from "src/config";

const currency = "HKD";

export const AtPay = (props: any) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("orderId") || "";
  const amount = searchParams.get("amount") || "";
  const merchantId = searchParams.get("merchantId") || "";

  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState<string>("");
  const [activeIdx, setActiveIdx] = useState<number>(0); // Default select the first
  const [isAllowContinue, setIsAllowContinue] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<any>({});

  const { checkPaymentOrder, createPaymentOrder, submitCardPayment } =
    useAtPayStore();

  useEffect(() => {
    if (!orderId) {
      openWarningNotification("Order ID is invalid");
      return history.push("/error");
    }
    if (!amount) {
      openWarningNotification("Amount is invalid");
      return history.push("/error");
    }
    if (!merchantId) {
      openWarningNotification("Merchant ID is invalid");
      return history.push("/error");
    }
  }, [orderId, amount, merchantId]);

  const startPayment = async () => {
    if (!orderId || !method || !amount || !merchantId)
      return openWarningNotification("Failed to continue payment");

    let paymentMethod = method;
    if (method === "visamaster") paymentMethod = "uprise";

    setLoading(true);
    const order = await createPaymentOrder({
      order_id: orderId,
      merchant_id: merchantId,
      payment_method: paymentMethod,
      amount,
    });

    setLoading(false);
    if (typeof order !== "object")
      return openWarningNotification("Failed to create order");
    setPaymentDetails(order);
    setIsAllowContinue(true);
  };

  const isAllowedContinue = () => {
    if (!orderId || !amount || !method || loading) return false;
    return true;
  };

  const handleCancel = () => setIsAllowContinue(false);

  if (!orderId || !amount || !merchantId) return null;

  return (
    <Container
      from="at-pay"
      theme="grey"
      showArrow={isAllowContinue}
      handleCallback={() => handleCancel()}
    >
      {loading && (
        <div className="z-5 w-full h-full fixed top-0 left-0 items-center justify-center flex">
          <LoadingIcon color="#427cdd" />
        </div>
      )}

      <div className="w-full mt-[60px] px-[20px] overflow-y-scroll">
        <AtPayOrderSummary
          currency={currency}
          amount={amount}
          orderId={orderId}
        />

        {/* Select Payment Method */}
        {!isAllowContinue && (
          <AtPaySelectPayment
            loading={loading}
            activeIdx={activeIdx}
            isAllowedContinue={isAllowedContinue}
            setMethod={setMethod}
            setActiveIdx={setActiveIdx}
            startPayment={startPayment}
            options={Configs.selectPaymentOption}
          />
        )}

        {isAllowContinue && (
          <AtPayConfirmPaymentBody
            method={method}
            merchantId={merchantId}
            paymentDetails={paymentDetails}
            checkPaymentOrder={checkPaymentOrder}
            submitCardPayment={submitCardPayment}
          />
        )}
      </div>
    </Container>
  );
};
