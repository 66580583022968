import React from "react";
import { OctopusPayFormButton } from "../octopus-payform-button";

export const OctopusPaymentView = (props: any) => {
  const { isCheckingPayment, paymentDetails = {} } = props;
  const { amount, order_id } = paymentDetails;
  return (
    <div>
      <h3 className="font-[600] text-[20px] text-left pb-[10px]">
        Instruction
      </h3>
      <div className="flex justify-center items-center bg-white min-h-[350px] p-[20px] rounded-[20px] cursor-pointer">
        <p>Click Pay Now will redirect you to the payment guide</p>;
      </div>

      {/* Pay Button  */}
      <div className="fixed left-0 bottom-[25px] w-full text-center flex justify-center items-center">
        <OctopusPayFormButton
          className={`${
            !isCheckingPayment ? $btn : $disabledBtn
          } w-[180px] py-[15px] rounded-[20px] text-[18px]`}
          amount={amount}
          orderId={order_id}
          loading={isCheckingPayment}
        />
      </div>
    </div>
  );
};

const $btn =
  "bg-gradient-to-r from-[#f894fa99] from-5% via-[#427cdd] via-40% to-[#6CBADB] to-90%";
const $disabledBtn = "bg-[#dddddd]";
