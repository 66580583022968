import React from "react";
import { Container } from "../components";

export const OctopusSuccess = (props: any) => {
  return (
    <Container>
      <div className="flex flex-col items-center justify-center">
        <img
          src="/assets/paid.gif"
          alt="paid"
          className="w-[200px] object-fit"
        />
        <p className="mt-[20px]">Success</p>
        <button
          onClick={() => {
            window.open("", "_self", "");
            return window.close();
          }}
          className="mt-[30px]"
        >
          Close and Return
        </button>
      </div>
    </Container>
  );
};
