import React from "react";
import * as Utils from "../utils";
export const SelectPayment = (props: any) => {
  const {
    method,
    setMethod,
    paymentMethod,
    bgColor = "#FFF",
    txtColor = "#000",
    activeBgColor = "#6018C3",
    activeTxtColor = "#FFF",
  } = props;

  return (
    <div className="flex flex-row space-x-[15px]">
      {paymentMethod.map((item: any, i: any) => {
        return (
          <button
            className={`${$btnStyle}`}
            onClick={() => {
              setMethod(item);
            }}
            key={i}
            style={{
              backgroundColor: method === item ? activeBgColor : bgColor,
              color: method === item ? activeTxtColor : txtColor,
              borderColor: method === item ? activeBgColor : activeBgColor,
            }}
          >
            {Utils.getPaymentMethodName(item)}
          </button>
        );
      })}
    </div>
  );
};
const $btnStyle = "w-full py-[10px] rounded-md text-[#000] border-[1px]";
