require("dotenv").config();

export const REVERSE_API_URL = process.env.REACT_APP_REVERSE_API_URL;
export const ASIATOP_API_URL = process.env.REACT_APP_ASIATOP_API_URL;
export const ONETS_API_URL = process.env.REACT_APP_ONETS_API_URL;
export const N3_CERAMICS_API_URL = process.env.REACT_APP_N3_CERAMICS_API_URL;

export const AIRWALLEX_ENV: any = process.env.REACT_APP_ENV || "demo";
export const STRIPE_KEY: any = process.env.REACT_APP_STRIPE_PK;

export const selectPaymentOption = [
  "octopus", // AsiaPay
  "payme", // HSBC
  "visamaster", // Uprise
  "yunshanfu",
  "unionpay", // Silkpay
  "fps", // AirWallex
  "alipay",
];

export const acceptPayment = ["octopus", "payme", "visamaster"];
