import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../components";
import { SelectPayment } from "src/components/select-payment";

const paymentMethod = ["unionpay"];

export const OnetsV2 = (props: any) => {
  const [amount, setAmount] = useState<number>(500);
  const [method, setMethod] = useState<string>(paymentMethod[0]);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (!method) return;
    if (method === "unionpay") setCurrency("EUR");
  }, [method, setCurrency]);

  const estimatedValue = useMemo(() => {
    if (!amount) return;
    return amount * 8.6;
  }, [amount]);

  const resetForm = () => {
    setAmount(500);
  };

  return (
    <Container from="onets" theme="light">
      <div className="flex flex-col w-full p-[20px] absolute">
        <h1 className="text-[30px] font-[700] os-font leading-[36px] logo-txt">
          Onets
        </h1>

        {/* Voucher Name */}
        <div className="pt-[15px] flex flex-row items-start">
          <img
            src="/assets/energy.jpeg"
            alt="voucher"
            className="w-[100px] object-fit"
          />
          <div className="ml-[20px] py-[15px]">
            <h5 className={$voucherTitleStyle}>Energy Voucher</h5>
            <p className={$voucherAmountStyle}>
              {currency} €{String(amount)}
            </p>
          </div>
        </div>

        <div className="pt-[20px]">
          <SelectPayment
            method={method}
            setMethod={setMethod}
            paymentMethod={paymentMethod}
          />
        </div>

        <div className="pt-[20px]">
          <p>Amount (EUR)</p>
          <input
            className={$inputClass}
            value={amount}
            onChange={(e: any) => setAmount(Number(e?.target?.value))}
          />
        </div>

        <div className="pt-[20px]">
          <p>Converted Amount (HKD)</p>
          <input
            className={`${$inputClass} not-allowed`}
            value={estimatedValue}
            disabled={true}
          />
        </div>

        <div className="pt-[20px]">
          <p className="text-center">
            You can simulate the exchange rate above
          </p>
        </div>

        <div className="pt-[20px] flex flex-col items-center">
          <button
            className="bg-[#6018C3]"
            onClick={() =>
              window.open(
                "https://paybylink.unionpayintl.com/payment/#/pc/paybyLink/payment?code=tG84mOtq&lang=zh_CN&fromResultPage=1",
                "_self"
              )
            }
          >
            Pay Now
          </button>
          <p
            className="cursor-pointer mt-[10px] font-[400]"
            onClick={() => resetForm()}
          >
            Reset Form
          </p>
        </div>
      </div>
    </Container>
  );
};

const $voucherTitleStyle = "text-[12px] font-[500] text-slate-500";
const $voucherAmountStyle = "text-[20px] font-[600] text-black";
const $inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";
