import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { VideoBg } from "../video-bg";

const videoScreenHeader = ["os"];

export const Container = (props: any) => {
  const {
    children,
    from,
    theme,
    showArrow = false,
    handleCallback = () => {},
  } = props;
  const location = useLocation();

  const CIRCLE_RADIUS_X = 640; //w
  const CIRCLE_RADIUS_Y = 80; //h

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const getHeaderStyle = () => {
    if (from === "payme") {
      return {
        background: "#dc0010",
      };
    }

    if (from === "onets" || from === "turbo") {
      return {
        backgroundImage: 'url("/assets/map.jpeg")',
        backroundSize: "cover",
        backgroundPosition: "50%",
      };
    }

    if (from === "luxury") {
      return {
        backgroundImage: 'url("/assets/luxury.jpeg")',
        backroundSize: "cover",
        backgroundPosition: "50%",
      };
    }

    if (from === "n3-ceramics") {
      return {
        backgroundImage: 'url("/assets/ceramics/bg.webp")',
        backroundSize: "cover",
      };
    }

    return {};
  };

  const getPageBg = (from: string) => {
    // From AT Pay
    if (from === "at-pay") {
      return (
        <div
          className="h-[150px] w-[640px] fixed translate-x-[-50%] text-center w-[100%] max-w-[100%] sm:max-w-[640px] rounded-full
          from-[#2D6FDF] bg-gradient-to-r from-5% via-[#6CBADB] via-45% to-[#f894fa99] to-100%  
          flex justify-center items-center rounded-full top-[-40px] mx-[50%]"
          style={{
            borderRadius: `${CIRCLE_RADIUS_X}px / ${CIRCLE_RADIUS_Y}px`,
            zIndex: 1,
          }}
        >
          <div className="w-full pt-[40px] relative">
            {showArrow && (
              <div
                className="flex justify-center items-center overflow-hidden w-[35px] h-[35px] absolute left-[35px] top-[35px] bg-[#ffffff4d] rounded-[35px]"
                onClick={() => handleCallback()}
              >
                <img
                  className="w-[20px] h-[20px] cursor-pointer"
                  src={`/assets/left-arrow.png`}
                  alt={"arrow-icon"}
                />
              </div>
            )}
            <p className={"text-[20px] font-[600] text-white w-full"}>AT Pay</p>
          </div>
        </div>
      );
    }
    // Default Gradient Bg
    return (
      <div
        className={`h-[120px] w-full max-w-[100%] sm:max-w-[640px] bg-gradient-to-r ${
          from === "octopus"
            ? "from-[#fdad1d] to-[#fffa0a]"
            : "from-cyan-500 to-blue-500"
        } mx-auto`}
        style={getHeaderStyle()}
      ></div>
    );
  };

  // Children Height
  const getHeighStyle = (from: string) => {
    if (from === "at-pay") return { height: "100vh" };
    return { height: "calc(100vh - 120px)" };
  };

  return (
    <div className={`min-h-screen min-w-screen bg-white`}>
      {videoScreenHeader.indexOf(from) < 0 && getPageBg(from)}

      {/* If from OS */}
      {from === "os" && <VideoBg />}

      {/* Children */}
      <div
        className={`m-auto max-w-[100%] sm:max-w-[640px] bg-cover relative flex flex-1 justify-center p-[20px] shadow-md 
        ${theme === "dark" && "bg-[#2E2D4D]"} 
        ${theme === "grey" && "bg-[#F8F9FC] pt-[80px]"}
        "bg-white" 
        overflow-hidden overflow-y-scroll`}
        id="home-container"
        style={getHeighStyle(from)}
      >
        {children}
      </div>
    </div>
  );
};
