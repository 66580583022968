import { generalError } from ".";

export const errorHandling = (e: any) => {
  if (!e) return generalError;

  const msg = e.response.data.message;
  switch (msg) {
    case "transaction_not_found":
      return { success: false, error: "Payment transaction not found" };
    case "invalid_token":
      return { success: false, error: "Invalid payment token" };
    case "payment_order_not_accepted":
      return { success: false, error: "Payment failed" };
    case "payment_amount_not_match":
      return {
        success: false,
        error: "Payment amount does not match to our records",
      };
    case "token_expired":
      return {
        success: false,
        error: "Payment token expired. Please try again",
      };
    default:
      return generalError;
  }
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return localStorage.setItem("at-birds-token", token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("at-birds-token")}`,
    },
  };

  return config;
};
