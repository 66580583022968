import React, { useEffect } from "react";
import { useLocation } from "react-router";

import { Container } from "src/components";
import { OctopusPayFormButton } from "src/components/octopus-payform-button";
import { history } from "src/stores";

export const Octopus = (props: any) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("orderId") || "";
  const amount = searchParams.get("amount") || "";
  const merchant = searchParams.get("merchant") || "";

  useEffect(() => {
    if (!orderId || !amount || !merchant) return history.push("/error");
  }, [orderId, amount, merchant]);

  if (!orderId || !amount || !merchant) return null;

  return (
    <Container from="octopus">
      <div className="flex flex-col w-[100%] h-[100%]">
        {/* Title Row */}
        <div className="flex flex-row items-center justify-center">
          <p className="text-[16px] text-center text-[#334155]">Octopus</p>

          {/* Octopus Icon */}
          <div className="rounded-full overflow-hidden bg-white ml-[5px]">
            <img
              src="/assets/octopus.png"
              alt="octopus"
              className="w-[30px] object-fit"
            />
          </div>
        </div>

        {/* Body */}
        <div className="flex flex-col h-full items-center pt-[80px]">
          <p className="text-[20px] text-[#334155] pb-[50px] text-center w-full">
            HKD ${amount}
          </p>
          <p className="text-[11px] text-slate-300 text-center w-full">
            Order ID: {orderId}
          </p>

          <div className="w-full flex justify-center py-[50px]">
            <img
              src="/assets/secure-payment.png"
              alt="octopus-card"
              className="w-[170px] object-fit"
            />
          </div>

          <p className="text-[11px] text-[#334155] text-center w-full">
            Click Pay Now will redirect you to the payment guide
          </p>

          <OctopusPayFormButton
            amount={amount}
            orderId={orderId}
            className="fixed bottom-[10%] px-[50px] rounded-full bg-gradient-to-r py-[11px] from-[#fdad1d] to-[#f65a17]"
          />
        </div>
      </div>
    </Container>
  );
};
