import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  openSuccessNotification,
  openWarningNotification,
} from "../components";
import { usePaymentStore } from "src/stores";
import { LoadingIcon } from "src/components/loading-icon";
import { ClientForm } from "src/components/client-form";
import { LoadStripeOrder } from "src/components/load-stripe-order";
import { SelectPayment } from "src/components/select-payment";
import { LoadOctopusOrder } from "src/components/load-octopus-order";
import axios from "axios";
import { LoadYunshanfuOrder } from "src/components/load-yunshanfu-order";
import { getRandomHandbag, getRandomWatch } from "src/utils";

const merchant = "luxury";
const rate = 1;
const maxAmountAllow = 3000;
const paymentMethod = ["octopus", "unionpay", "yunshanfu"];
const idx = String(Math.floor(Math.random() * 9));

export const Luxury = (props: any) => {
  const { product = "handbang", amount: defaultAmount = 500 } =
    props?.match?.params;

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [email, setEmail] = useState<any>("");
  const [firstname, setFirstname] = useState<any>("");
  const [lastname, setLastname] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [street, setStreet] = useState<any>("");
  const [postalCode, setPostalCode] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [amount, setAmount] = useState<number>(defaultAmount);
  const [method, setMethod] = useState<string>(paymentMethod[0]);
  const [step, setStep] = useState<any>("form"); // form, payment
  const [currency, setCurrency] = useState("");
  const [qrcode, setQRCode] = useState("");

  const { createPaymentOrder, updateEmailPaymentOrder } = usePaymentStore();

  const createOrderCb = useCallback(async () => {
    if (!amount || amount <= 0)
      return openWarningNotification("Order amount has to be larger than 0");

    let extraArgs = {};

    if (method === "unionpay") {
      const requiredFields =
        firstname &&
        lastname &&
        phone &&
        street &&
        postalCode &&
        city &&
        country &&
        email;
      if (!requiredFields)
        return openWarningNotification("Please fill in all required fields");

      const location = `${origin}`;
      const ipRes = await axios.get("https://api.ipify.org/?format=json");
      extraArgs = {
        email,
        firstname,
        lastname,
        phone,
        street,
        postalCode,
        urlOk: `${location}/checkout-success`,
        cancelUrl: `${location}/turbo`,
        ip: ipRes["data"]["ip"],
        country,
      };
    }

    setLoading(true);
    const currencyAmount = Number((Number(amount) / rate).toFixed(2));
    let order = await createPaymentOrder(
      {
        amount: currencyAmount,
        currency: currency,
        method: method,
        fee: "0",
        ...extraArgs,
      },
      merchant
    );
    setLoading(false);
    if (typeof order !== "object")
      return openWarningNotification("Order cannot be created");
    openSuccessNotification("Order is created");
    setOrder(order);
    return order;
  }, [
    createPaymentOrder,
    amount,
    method,
    firstname,
    lastname,
    country,
    city,
    phone,
    street,
    postalCode,
    email,
    currency,
  ]);

  useEffect(() => {
    if (!method) return;
    if (method === "octopus") setCurrency("HKD");
    if (method === "unionpay") setCurrency("EUR");
    if (method === "yunshanfu") setCurrency("EUR");
  }, [method, setCurrency]);

  const resetForm = () => {
    setFirstname("");
    setLastname("");
    setAmount(500);
    setPhone("");
    setStreet("");
    setPostalCode("");
    setCity("");
    setCountry("");
    setStep("form");
  };

  const submitClientForm = async () => {
    if (method === "octopus" && Number(amount) > maxAmountAllow)
      return openWarningNotification(
        `Order amount cannot be greater than ${maxAmountAllow}`
      );

    if (method === "yunshanfu" && Number(amount) > 599) {
      return openWarningNotification(
        `Yun Shan Fu amount cannot be greater than 599 EUR`
      );
    }

    if (!email) return openWarningNotification("Please enter email");
    const order = await createOrderCb();
    const isUpdated = await updateEmailPaymentOrder(
      { email: email.trim(), orderId: order["order_id"] },
      merchant
    );
    if (!isUpdated)
      return openWarningNotification("Cannot proceed at the moment");

    if (method === "unionpay") {
      const url = order?.redirect_url;
      if (url) {
        resetForm();
        return window.open(url, "_self");
      }
      return;
    }

    if (method === "yunshanfu") {
      setQRCode(order["generated_code_url"]);
    }
    setStep("payment");
  };

  const getItem = () => {
    switch (product) {
      case "watch":
        return getRandomWatch(idx);
      case "handbag":
      default:
        return getRandomHandbag(idx);
    }
  };

  return (
    <Container from="luxury" theme="light">
      {loading && (
        <div className="z-[9999] w-full h-full fixed top-0 left-0 items-center justify-center flex">
          <LoadingIcon color="#9C5FF1" />
        </div>
      )}

      <div className="flex flex-col w-full p-[20px] absolute">
        <h1 className="text-[30px] font-[700] leading-[36px] logo-txt">
          AsiaTop Luxury
        </h1>

        {/* Voucher Name */}
        <div className="pt-[15px] flex flex-row items-start">
          <img
            src={`/assets/${getItem().img}`}
            alt={product}
            className="w-[100px] object-fit"
          />
          <div className="ml-[20px] py-[15px]">
            <h5 className={$voucherTitleStyle}>{getItem().name}</h5>
            <p className={$voucherAmountStyle}>
              {currency} ${String(amount)}
            </p>
          </div>
        </div>

        {step === "form" && (
          <div className="pt-[20px]">
            <SelectPayment
              method={method}
              setMethod={setMethod}
              paymentMethod={paymentMethod}
              activeBgColor={"#C89600"}
            />
          </div>
        )}

        <div className="pt-[20px]">
          {step === "form" && (
            <ClientForm
              value={email}
              setValue={setEmail}
              submit={submitClientForm}
              firstname={firstname}
              setFirstname={setFirstname}
              lastname={lastname}
              setLastname={setLastname}
              phone={phone}
              setPhone={setPhone}
              street={street}
              setStreet={setStreet}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              amount={amount}
              setAmount={setAmount}
              method={method}
              bgColor={"#C89600"}
            />
          )}

          {order && step === "payment" && method === "stripe" && (
            <LoadStripeOrder
              clientSecret={order["clientSecret"]}
              amount={amount}
              currency={"USD"}
              setLoading={setLoading}
              orderId={order["order_id"]}
              merchant={merchant}
              loading={loading}
              email={email}
              name={firstname}
              country={country}
            />
          )}

          {order && step === "payment" && method === "octopus" && (
            <LoadOctopusOrder
              orderId={order["order_id"]}
              amount={String(amount)}
              returnUrl={"/turbo"}
            />
          )}

          {order && step === "payment" && method === "yunshanfu" && (
            <LoadYunshanfuOrder qrCode={qrcode} reset={() => resetForm()} />
          )}
        </div>
      </div>
    </Container>
  );
};

const $voucherTitleStyle = "text-[12px] font-[500] text-slate-500";
const $voucherAmountStyle = "text-[20px] font-[600] text-black";
