import React, { useEffect } from "react";
import { Container } from "../../components";
import { createElement, loadAirwallex } from "airwallex-payment-elements";
import { history, usePaymentStore } from "src/stores";
import { useLocation } from "react-router";
import { AIRWALLEX_ENV } from "src/config";

export const Home = (props: any) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const client_secret = searchParams.get("client_secret");
  const currency = searchParams.get("currency");

  const { sendNotification } = usePaymentStore();

  useEffect(() => {
    if (!id || !client_secret || !currency) return history.push("/error");

    const loadDropInElement = async () => {
      try {
        // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
        await loadAirwallex({
          env: AIRWALLEX_ENV, // Can choose other production environments, 'staging | 'demo' | 'prod'
          origin: window.location.origin, // Setup your event target to receive the browser events message
          // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
        });
        // STEP #4: Create the drop-in element
        const element = createElement("dropIn", {
          // Required, dropIn use intent Id, client_secret and currency to prepare checkout
          intent_id: id,
          client_secret,
          currency,
          style: {
            // the 3ds popup window dimension
            popupWidth: 400,
            popupHeight: 549,
          },
          components: [
            "fps",
            "wechatpay",
            "alipayhk",
            "alipaycn",
            "googlepay",
            "applepay",
          ],
        });
        // STEP #5: Mount the drop-in element to the empty container created previously
        element?.mount("dropIn"); // This 'dropIn' id MUST MATCH the id on your empty container created in Step 4
      } catch (error) {
        console.error(error);
      }
    };
    loadDropInElement();
    // STEP #6: Add an event listener to handle events when the element is mounted
    const onReady = (event: CustomEvent): void => {
      /**
       * Handle events on element mount
       */
      console.log(
        `Element is mounted: ${JSON.stringify(
          event.detail
        )}, env: ${AIRWALLEX_ENV}`
      );
    };

    // STEP #7: Add an event listener to handle events when the payment is successful.
    const onSuccess = (event: CustomEvent): void => {
      /**
       * Handle events on success
       */
      console.log(`Confirm success with ${JSON.stringify(event.detail)}`);
      sendNotification(
        {
          orderId: id,
        },
        "asiatop"
      ).then((result) => {
        history.push("/checkout-success");
      });
    };

    // STEP #8: Add an event listener to handle events when the payment has failed.
    const onError = (event: CustomEvent) => {
      /**
       * Handle events on error
       */
      const { error } = event.detail;
      console.error("There is an error", error);
      history.push("/error");
    };
    const domElement = document.getElementById("dropIn");
    domElement?.addEventListener("onReady", onReady as EventListener);
    domElement?.addEventListener("onSuccess", onSuccess as EventListener);
    domElement?.addEventListener("onError", onError as EventListener);
    return () => {
      domElement?.removeEventListener("onReady", onReady as EventListener);
      domElement?.removeEventListener("onSuccess", onSuccess as EventListener);
      domElement?.removeEventListener("onError", onError as EventListener);
    };
  }, [id, client_secret, currency, sendNotification]); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  // Example: Custom styling for the dropIn container, can be placed in css
  const containerStyle = {
    width: "540px",
    margin: "48px auto",
  };

  if (!id || !client_secret || !currency) return null;

  return (
    <Container>
      <div id="dropIn" style={{ ...containerStyle }}></div>
    </Container>
  );
};
