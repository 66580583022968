const { notification } = require("antd");

type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string
) => {
  return notification[type]({
    message: title,
    description: message,
  });
};

export const openErrNotification = (message: string) => {
  return openNotificationWithIcon("error", "Error", message);
};

export const openSuccessNotification = (message: string) => {
  return openNotificationWithIcon("success", "Congratulations", message);
};

export const openWarningNotification = (message: string) => {
  return openNotificationWithIcon("warning", "Sorry", message);
};

export const openInfoNotification = (message: string) => {
  return openNotificationWithIcon("info", "Sorry", message);
};
