import React from "react";
import { LoadingIcon } from "./loading-icon";

export const OctopusPayFormButton = (props: any) => {
  const { className = "", amount, orderId, loading = false } = props;
  return (
    <div className={className}>
      {/* Pay Now Button */}
      <form
        name="payForm"
        method="post"
        action={process.env.REACT_APP_MERCHANT_URL}
      >
        <input
          type="hidden"
          name="merchantId"
          value={process.env.REACT_APP_MERCHANT_ID}
        />

        {/* The Max amount is 5000 HKD */}
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="orderRef" value={orderId} />
        <input type="hidden" name="currCode" value="344" />
        <input type="hidden" name="payMethod" value="OCTOPUS" />

        <input
          type="hidden"
          name="successUrl"
          value={`${process.env.REACT_APP_RETURN_URL}/octopus-checkout-success`}
        />
        <input
          type="hidden"
          name="failUrl"
          value={`${process.env.REACT_APP_RETURN_URL}/error`}
        />
        <input
          type="hidden"
          name="errorUrl"
          value={`${process.env.REACT_APP_RETURN_URL}/error`}
        />
        <input type="hidden" name="lang" value="E" />

        {loading ? (
          <div className="flex justify-center items-center">
            <LoadingIcon size={26} color="#fff" className="text-[10px]" />
          </div>
        ) : (
          <input
            type="submit"
            className="text-[#fff] font-bold"
            value="Pay Now"
          />
        )}
      </form>
    </div>
  );
};
