import React, { useState } from "react";
import { history } from "src/stores";
import { openErrNotification, openWarningNotification } from "../notification";
import { VisaMasterPaymentView } from "../at-pay-visamaster-payment-view";
import { AtPaySelectMethodOption } from "./select-method-option";
import { OctopusPaymentView } from "./octopus-payment-view";
import { PayMePaymentView } from "./payme-payment-view";

export const AtPayConfirmPaymentBody = (props: any) => {
  const {
    method,
    merchantId = "",
    paymentDetails = {},
    checkPaymentOrder,
    submitCardPayment,
  } = props;

  const [isCheckingPayment, setIsCheckingPayment] = useState<boolean>(false);
  const [isApproving, setApproving] = useState<boolean>(false);

  // Check payment
  const checkPayment = async () => {
    if (isCheckingPayment) return;
    if (!merchantId) return;
    const { order_id } = paymentDetails;
    setIsCheckingPayment(true);

    try {
      const order = await checkPaymentOrder(
        `?order_id=${order_id}&merchant_id=${merchantId}`
      );
      setIsCheckingPayment(false);
      if (typeof order !== "object")
        return openWarningNotification("Failed to check payment order");
      const status = order["status"];
      switch (status) {
        case "created": // pending payment
          openWarningNotification("Pending for payment confirmation");
          break;
        case "done": // success
          history.push("/checkout-success");
          break;
        case "cancelled":
          openWarningNotification("Payment has cancelled");
          break;
        case "failed":
          openErrNotification("Payment has failed. Please try again");
          break;
        default:
          openErrNotification("An unexpected error occurred");
          break;
      }
      setIsCheckingPayment(false);
    } catch (error) {
      setIsCheckingPayment(false);
    }
  };

  return (
    <React.Fragment>
      {/* Selected Payment Method */}
      <div className="mt-[20px]">
        <h3 className="font-[600] text-[20px] text-left pb-[10px]">
          Payment Methods
        </h3>
        <AtPaySelectMethodOption
          active={true}
          onClick={() => {}}
          name={method}
        />
      </div>

      {/* Payment View */}
      {method === "octopus" && (
        <OctopusPaymentView
          isCheckingPayment={isCheckingPayment}
          paymentDetails={paymentDetails}
        />
      )}

      {method === "payme" && (
        <PayMePaymentView
          isCheckingPayment={isCheckingPayment}
          paymentDetails={paymentDetails}
          checkPaymentOrder={checkPayment}
        />
      )}

      {method === "visamaster" && (
        <VisaMasterPaymentView
          isApproving={isApproving}
          paymentDetails={paymentDetails}
          submitCardPayment={submitCardPayment}
          setApproving={setApproving}
        />
      )}
    </React.Fragment>
  );
};
