import React, { useCallback, useEffect } from "react";
import { Container } from "../components";
import { usePaymentStore } from "src/stores";
import { useLocation } from "react-router";

const merchant = "n3-ceramics";

export const N3Ceramicsuccess = (props: any) => {
  const { confirmPaymentOrder } = usePaymentStore();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("orderId") || "";
  const idx = searchParams.get("idx") || "";
  const method = searchParams.get("method") || "stripe";

  const confirmOrderCb = useCallback(async () => {
    await confirmPaymentOrder(
      {
        orderId: orderId,
        idx: idx,
        merchant: merchant,
      },
      merchant
    );
  }, [orderId, confirmPaymentOrder, idx]);

  useEffect(() => {
    if (!orderId || !method) return;
    confirmOrderCb();
  }, [orderId, method, confirmOrderCb]);

  if (!orderId) return null;

  return (
    <Container from="n3-ceramics" theme="light">
      <div className="flex flex-col items-center justify-center w-full p-[10px]">
        <h1 className="text-[16px] font-[500] leading-[36px] n3-txt uppercase">
          N3 Ceramics
        </h1>

        <img
          src="/assets/paid.gif"
          alt="paid"
          className="w-[200px] object-fit"
        />

        <p className="mt-[20px] n3-txt text-center font-[400] text-slate-500 text-[12px]">
          Successfully paid the order, please confirm your receipt and reply us
          with the email we sent you
        </p>
      </div>
    </Container>
  );
};
