import React, { useState } from "react";
import { history } from "src/stores";

import { LoadingIcon } from "../loading-icon";
import { openWarningNotification, openErrNotification } from "../notification";
import { VisaMasterCardView } from "../at-pay/visa-master-card-view";

export const VisaMasterPaymentView = (props: any) => {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardHolder, setCardHolder] = useState<string>("");
  const [cvv, setCvv] = useState<string>("");
  const [expireDate, setExpireDate] = useState<string>("");
  const { isApproving, paymentDetails, submitCardPayment, setApproving } =
    props;

  const submitPayment = async () => {
    const { order_id, merchant_id } = paymentDetails;
    if (!cardNumber || !cardHolder || !expireDate || !cvv)

      return openWarningNotification(
        "Payment failed, please check your card details"
      );
    if (!order_id || !merchant_id) return;

    // Format the credit card number to remove all spaces
    const formattedCardNumber = cardNumber.replace(/\s/g, "");

    // Split the expireDate into month and year
    const [expireMonth, expireYear] = expireDate.split("/").map(Number);

    // Validate expiration date is after today's date
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // JavaScript months are 0-based
    const currentYear = today.getFullYear();

    if (
      expireYear < currentYear ||
      (expireYear === currentYear && expireMonth < currentMonth)
    ) {
      openErrNotification("Expiration date must greater after today's date");
      return; // Stop the function if the expiration date is not valid
    }

    setApproving(true);
    try {
      const order = await submitCardPayment({
        order_id,
        merchant_id,
        card_number: formattedCardNumber,
        card_month: expireMonth,
        card_year: expireYear,
        cvv,
      });

      if (typeof order !== "object") {
        setApproving(false);
        openErrNotification("Failed to submit card payment");
        return;
      }

      const status = order.status;
      switch (status) {
        case "created": // pending payment
          openWarningNotification("Pending for payment confirmation");
          break;
        case "done": // success
          history.push("/checkout-success");
          break;
        case "failed":
          openErrNotification("Payment has failed. Please try again");
          break;
        default:
          openErrNotification("An unexpected error occurred");
          break;
      }
      setApproving(false);
    } catch (error) {
      setApproving(false);
      openErrNotification(
        "An error occurred during the payment approval. Please try again."
      );
    }
  };

  const handleCreditCard = (e: any) => {
    // Get the value from the event and remove all non-numeric characters
    let value = e.target.value.replace(/\D/g, "");

    // Add a space every 4 characters
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    setCardNumber(formattedValue);
  };

  const handleExpireDate = (e: any) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters

    // Ensure the month is in the format MM
    if (value.length >= 2) {
      // Extract month and year parts
      let month = value.substring(0, 2);
      let year = value.substring(2);

      // Basic validation for month
      const monthNum = parseInt(month, 10);
      if (monthNum < 1 || monthNum > 12) {
        month = ""; // Reset month if invalid
      }

      // Combine month and year with slash if month is valid and year is present
      value = month + (year.length > 0 && month ? "/" : "") + year;
    }
    setExpireDate(value); // Update the state with the formatted value
  };

  const handleCVV = (e: any) => {
    let value = e.target.value.replace(/\D/g, "");
    setCvv(value);
  };

  const handleCardHolder = (e: any) => {
    // Remove everything that is not a letter
    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allow letters and spaces
    setCardHolder(value);
  };

  return (
    <div>
      <h3 className="font-[600] text-[20px] text-left pb-[10px]">
        Credit or Debit Card
      </h3>

      {/* Visa Master Card Body */}
      <VisaMasterCardView
        cardNumber={cardNumber}
        cardHolder={cardHolder}
        expireDate={expireDate}
        cvv={cvv}
        handleCreditCard={handleCreditCard}
        handleCardHolder={handleCardHolder}
        handleExpireDate={handleExpireDate}
        handleCVV={handleCVV}
      />

      {/* Pay Button  */}
      <div className="fixed left-0 bottom-[25px] w-full text-center">
        <button
          className={`${
            !isApproving ? $btn : $disabledBtn
          } w-[180px] py-[15px] rounded-[20px] text-[18px]`}
          onClick={() => submitPayment()}
          disabled={isApproving}
        >
          {isApproving ? (
            <div className="flex justify-center items-center">
              <LoadingIcon size={26} color="#427cdd" className="text-[10px]" />
            </div>
          ) : (
            "Pay Now"
          )}
        </button>
      </div>
    </div>
  );
};

const $btn =
  "bg-gradient-to-r from-[#f894fa99] from-5% via-[#427cdd] via-40% to-[#6CBADB] to-90%";
const $disabledBtn = "bg-[#dddddd]";
