import React from "react";
import QRCode from "react-qr-code";

export const LoadGcQRCode = (props: any) => {
  const { qrCode, reset } = props;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="py-[40px] w-[250px] text-center">
        <QRCode value={qrCode} style={{ margin: "auto" }} />
      </div>

      <button className="primary mt-[20px]" onClick={reset}>
        Return
      </button>
    </div>
  );
};
