import React from "react";
import * as Utils from "../../utils";
import * as Configs from "../../config";

export const AtPaySelectMethodOption = (props: any) => {
  const { active, onClick, name } = props;
  const notAcceptedPaymentMethod = Configs.acceptPayment.indexOf(name) < 0;

  return (
    <div
      className={`${
        active &&
        "from-[#F894FA4D] from-2% via-[#6cbadb] via-60% to-[#427cdd] to-100% bg-gradient-to-r cursor-pointer"
      }
      ${notAcceptedPaymentMethod && "!bg-[#ddd] cursor-not-allowed"}
      bg-white flex flex-row justify-between items-center p-[20px] mb-[20px] rounded-[20px]`}
      onClick={onClick}
    >
      {/* Payment Method Name */}
      <p
        className={`
        ${active && "text-white"}
        ${notAcceptedPaymentMethod && "text-white"}
        text-[#8D8F99] text-left text-[16px] font-[600]`}
      >
        {Utils.getPaymentMethodName(name)}
      </p>
      <div
        className={`${
          active ? "bg-[#3858CB] shadow-lg" : "bg-transparent"
        } rounded-[40px] w-[40px] h-[40px] flex justify-center items-center overflow-hidden
    `}
      >
        {active ? (
          <p className="text-[16px] text-white">&#10003;</p>
        ) : (
          <div className="overflow-hidden">
            <img
              className="w-[35px] h-[35px] object-contain bg-transparent"
              src={`/assets/${name}.png`}
              alt={Utils.getPaymentMethodName(name)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
