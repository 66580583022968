import React from "react";

export const LoadYunshanfuOrder = (props: any) => {
  const { qrCode, reset } = props;

  return (
    <div className="flex flex-col items-center justify-center">
      <img src={qrCode} alt="Yunshanfu-qrcode" className="w-[250px]" />
      <button className="primary mt-[20px]" onClick={reset}>
        Return
      </button>
    </div>
  );
};
