import React from "react";

export const CheckForm = (props: any) => {
  const {
    value,
    setValue,
    submit,
    bgColor = "#6018C3",
    txtColor = "#fff",
    labelColor = "#000",
  } = props;
  return (
    <div>
      <p style={{ color: labelColor }}>Order ID</p>
      <input
        type="text"
        name="order_id"
        className={$inputClass}
        placeholder=""
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      {/* Submit Button */}
      <button
        className="w-full mt-[20px] py-[10px] rounded-md"
        onClick={submit}
        style={{ backgroundColor: bgColor, color: txtColor }}
      >
        Check
      </button>
    </div>
  );
};

const $inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";
