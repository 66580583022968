import React, { useEffect } from "react";
import { openSuccessNotification, openWarningNotification } from ".";
import {
  createElement,
  ElementType,
  loadAirwallex,
  confirmPaymentIntent,
  getElement,
} from "airwallex-payment-elements";
import { AIRWALLEX_ENV } from "src/config";

export const LoadCardOrder = (props: any) => {
  const {
    id,
    amount,
    client_secret,
    currency,
    setOrder,
    loading,
    setLoading,
    txtColor,
  } = props;

  useEffect(() => {
    const loadCardOrder = async () => {
      await loadAirwallex({
        env: AIRWALLEX_ENV, // Can choose other production environments, 'staging | 'demo' | 'prod'
        origin: window.location.origin, // Setup your event target to receive the browser events message
      });

      const intent = {
        id: id,
        currency: currency,
        client_secret: client_secret,
      };

      createElement("card" as ElementType, {
        intent,
        origin: window.location.origin,
      })?.mount("card"); // This 'card' id MUST MATCH the id on your empty container created in Step 3
    };

    loadCardOrder();
  }, [id, client_secret, currency, setOrder]); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  // Example: Custom styling for the dropIn container, can be placed in css
  const containerStyle = {
    width: "100%",
    padding: "48px 0px",
  };

  if (!id || !client_secret || !currency) return null;

  const pay = async () => {
    if (loading) return;
    const card: any = getElement("card");
    console.log("confirm", card, id, client_secret);
    setLoading(true);
    confirmPaymentIntent({
      element: card,
      intent_id: id,
      client_secret: client_secret,
    })
      .then((response) => {
        // Listen to the payment confirmation response
        setLoading(false); // Example: sets loading state
        openSuccessNotification("Order is successfully paid");
        // setOrder(null);
      })
      .catch((error) => {
        /**
         * ... Handle error response
         */
        setLoading(false); // Example: sets loading state
        openWarningNotification("Failed to pay");
        window.alert(JSON.stringify(error));
      });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <p>
        <span className={$labelStyle}>Order ID</span> {id}
      </p>
      <>
        <div
          id="card"
          style={{
            ...containerStyle,
          }}
        ></div>
        <button id="submit" className="full" onClick={() => pay()}>
          Pay {amount} {currency}
        </button>
      </>
      <button
        className="full mt-[20px] !bg-transparent"
        onClick={() => setOrder(null)}
        style={{ color: txtColor }}
      >
        Clear all
      </button>
    </div>
  );
};

const $labelStyle = "w-[100px] inline-block font-[500]";
