// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { PaymentStore, STORE_PAYMENT } from "./payment";
import { AtPayStore, STORE_AT_PAY } from "./at-pay";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_PAYMENT]: new PaymentStore(),
    [STORE_AT_PAY]: new AtPayStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function usePaymentStore() {
  const { paymentStore } = useStores();
  return paymentStore;
}

export function useAtPayStore() {
  const { atPayStore } = useStores();
  return atPayStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
